<template>
  <div class="box">
    <div :class="{'box-result': isDesktop, 'box-mobile': !isDesktop}">
      <LazySearchBox is-fold :is-mobile="isMobileOrTablet"/>
      <LazyEmpty v-if="data?.code === 500" show-btn :title="data.msg"></LazyEmpty>
      <div v-else class="wrap topic-detail" v-if="type === 'answer' && data">
        <Topic :info="data"/>
      </div>
      <div class="wrap" v-if="data?.list">
        <SearchResult :topicList="data.list" :title="title" />
        <div class="page-box" v-if="data?.totalcount">
          <Pagination
            :total="data.totalcount"
            :page-size="data.pagesize"
          />
        </div>
      </div>
    </div>
    <template v-if="isDesktop">
      <el-affix :offset="130">
          <LazyChat is-mini/>
      </el-affix>
    </template>
  </div>
</template>

<script setup>

import {  getStDetail, getDayDetails, getZjDetails, getAiList, getWangkeList, getQAList, getSmartList } from '~/api';
const { isDesktop, isMobileOrTablet } = useDevice();
const route = useRoute()
let data = ref();
const { id, guid, page } = route.params;
const { title: metaTitle, type, stfrom } = route.meta;

const title = ref(metaTitle && type !== 'answer' ? metaTitle : '你可能感兴趣的试题');
if (type === 'answer') { // 看答案页面

  data = await getStDetail({
    guid: guid || id,
    stfrom
  });
  console.log('[题目数据]:', data)
  data.value.list = data.value.stdetailslist;
} else { // 显示题目列表
  switch (route.name) {
    case 'chapter_list':
      data = await getZjDetails({ id, page });
      data.value.list = data.value.kstklist;
      title.value = data.value.title;
      break;
    case 'mip_chapter_list':
      data = await getZjDetails({ id, page });
      data.value.list = data.value.kstklist;
      title.value = data.value.title;
      break;
    case 'day_list':
      data = await getDayDetails({ id, page });
      data.value.list = data.value.kstklist;
      title.value = data.value.title;
      break;
    case 'mip_day_list':
      data = await getDayDetails({ id, page });
      data.value.list = data.value.kstklist;
      title.value = data.value.title;
      break;
    case 'wangke_list':
      data = await getWangkeList({page})
      data.value.list = data.value.wangkelist;
      break;
    case 'mip_wangke_list':
      data = await getWangkeList({page})
      data.value.list = data.value.wangkelist;
      break;
    case 'zhidao_list':
      data = await getQAList({page})
      data.value.list = data.value.zhidaolist;
      break;
    case 'smart_home_list':
      data = await getSmartList({page})
      data.value.list = data.value.smarthomelist;
      break;
    case 'kstkai_list':
      data = await getAiList({page})
      data.value.list = data.value.kstkailist;
      break;
  }
}

useSeo(data.value);

</script>

<style scoped lang="scss">
.box {
  max-width: var(--web-wdith);
  margin: var(--topic-list-pt) auto 0;
  @include flex-bet;
  &-result {
    flex: 1;
    max-width: calc(var(--web-wdith) - 350px);
    margin-right: 20px;
    border-radius: 8px;
  }
  &-mobile {
    border-radius: 8px;
    width: 100%;
    .wrap {
      padding-top: 12px !important;
    }
    :deep(.result-box) {
      padding: 0 12px;
    }
    :deep(.head-title) {
      padding-bottom: 12px;
      h1 {
        font-size: 16px;
      }
    }
    .topic-detail {
      padding: 12px !important;
    }
  }
  .topic-detail {
    padding: 30px;
  }
  .wrap {
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--card-bg-color);
    margin-top: 12px;
    padding-top: 30px;
  }
  .page-box {
    margin: var(--pagination-margin);
    @include flex-x-center;
  }
}
</style>
